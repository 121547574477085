<template>
<div>
  <div>
    <div class="content-header container-fluid bg-white">
      <div class="row">
        <div class="col-sm-4">
          <h4><i class="ti-world"> </i> 通用管理 | 提醒人员</h4>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <el-button type="primary" @click="rowEdit()">新增记录</el-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="w-block" style="padding: 2px;">
            <el-select v-model="grid.sea.TYPE" @change="filter" clearable placeholder="请选择分类" >
              <el-option label="维修" value="FIX" />
              <el-option label="物料" value="MAT" />
            </el-select>
            <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px" >
              <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
            </el-input>
          </div>
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1" @page-changed="loadPage">
            <el-table-column prop="ID" label="ID" width="60" />
            <el-table-column prop="TYPE_TXT" label="分类" width="100" />
            <el-table-column prop="TEA_NAME" label="姓名" width="100" />
            <el-table-column label="创建日期" width="100">
              <template slot-scope="scope" align="center">{{scope.row.CREATED_DT | datetime('YYYY-MM-DD')}}</template>
            </el-table-column>
            <el-table-column label="操作" >
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)" >删除</el-button>
              </template>
            </el-table-column>
          </wgrid>
        </div>
      </div>
    </div>
  </div>
  <div>
    <Edit ref="notice_edit" @on-saved="filter" />
  </div>
</div>
</template>

<script>
import Edit from "./edit";
import Wgrid from "@/components/wgrid";
export default {
  name: "index",
  components: {Wgrid, Edit},
  data(){
    return{
      grid:{
        sea:{
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          TYPE:"",
        },
        ls:[],
        total:0,
        loading:false
      }
    }
  },
  created() {
    this.getList();
  },
  methods:{
    filter(){
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi){
      this.grid.sea.PAGE_INDEX = pi-1;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/School/MSG/NoticeApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls=its;
          self.grid.total=n;
          self.grid.loading=false;
        }
      })
    },
    rowEdit(r){
      this.$refs.notice_edit.init(r);
    },
    rowDel(r){
      this.whale.delConfirm(()=>{
        let self=this;
        this.whale.remote.getResult({
          url:"/api/School/MSG/NoticeApi/Delete",
          data:{ID:r.ID},
          completed:function (){
            //self.filter();
            self.grid.ls=self.grid.ls.filter(o=>o.ID!=r.ID)
          }
        })
      });
    }
  }
}
</script>

